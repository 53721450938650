.booking-calendar {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    display: block;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
}
.booking-calendar .header {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #2196f3;
    border: solid 1px #e3f2fd;
    color: #fff;
    height: 3em;
    position: relative;
}
.booking-calendar .header .header-content .icon-previous, .booking-calendar .header .header-content .icon-next, .booking-calendar .header .header-content .month-label {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.125em;
    font-weight: bold;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
}
.booking-calendar .header .header-content .icon-previous:disabled, .booking-calendar .header .header-content .icon-next:disabled, .booking-calendar .header .header-content .month-label:disabled {
    color: #82c4f8;
}
.booking-calendar .header .header-content .icon-previous {
    left: 0;
}
.booking-calendar .header .header-content .icon-next {
    right: 0;
}
.booking-calendar .header .header-content .month-label {
    left: 0;
    right: 0;
    text-align: center;
}
.booking-calendar .week {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #2196f3;
    border-right: solid 1px #e3f2fd;
    border-top: solid 1px #e3f2fd;
    float: left;
    width: 100%;
}
.booking-calendar .week:last-child {
    border-bottom: solid 1px #e3f2fd;
}
.booking-calendar .week.names {
    border-top: none;
}
.booking-calendar .week.names .day-box:before {
    padding-top: 50%;
}
.booking-calendar .week.names .day-box .day {
    color: #fff;
    font-size: 0.9em;
}
.booking-calendar .week .day-box {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    position: relative;
    width: 14.2857142857%;
}
.booking-calendar .week .day-box:before {
    border-right: solid 1px #e3f2fd;
    content: '';
    display: block;
    padding-top: 100%;
}
.booking-calendar .week .day-box:first-child .day {
    border-left: solid 1px #e3f2fd;
}
.booking-calendar .week .day-box:last-child .day {
    border-right: none;
}
.booking-calendar .week .day-box .day {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #2fb53f;
    border-right: solid 1px #e3f2fd;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}
.booking-calendar .week .day-box .day.not-clickable {
    cursor: default;
}
.booking-calendar .week .day-box .day.different-month {
    background: #ffffff;
    color: #ffffff;
}
.booking-calendar .week .day-box .day.selected {
    background: #fff;
    color: #2196f3;
}
.booking-calendar .week .day-box .day.selected:before, .booking-calendar .week .day-box .day.selected:after {
    content: none;
}
.booking-calendar .week .day-box .day.today {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #2fb53f;
    border-right: solid 1px #e3f2fd;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}
.booking-calendar .week .day-box .day.booked-day {
    overflow: hidden;
    z-index: 1;
}
.booking-calendar .week .day-box .day.booked-day:before {
    -ms-transform: skewX(-18deg);
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    background-color: #f54245;
    content: '';
    height: 100%;
    left: 18%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.booking-calendar .week .day-box .day.booked-night {
    overflow: hidden;
    z-index: 1;
}
.booking-calendar .week .day-box .day.booked-night:after {
    -ms-transform: skewX(-18deg);
    -webkit-transform: skewX(-18deg);
    transform: skewX(-18deg);
    background-color: #f54245;
    content: '';
    height: 100%;
    left: -80%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.booking-calendar .week .day-box .day > .day-content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}
.booking-calendar .week.names > span {
    color: #82c4f8;
    font-weight: bold;
}
@media (min-width: 600px) {
    .booking-calendar {
        font-size: 18px;
    }
}
@media (min-width: 768px) {
    .booking-calendar {
        font-size: 20px;
    }
}
@media (min-width: 992px) {
    .booking-calendar {
        font-size: 24px;
    }
}
@media (min-width: 1200px) {
    .booking-calendar {
        font-size: 28px;
    }
}
